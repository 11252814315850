import { render, staticRenderFns } from "./newList.vue?vue&type=template&id=402f229e&scoped=true&"
import script from "./newList.vue?vue&type=script&lang=js&"
export * from "./newList.vue?vue&type=script&lang=js&"
import style0 from "./newList.vue?vue&type=style&index=0&id=402f229e&scoped=true&lang=scss&"
import style1 from "./newList.vue?vue&type=style&index=1&id=402f229e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "402f229e",
  null
  
)

export default component.exports