<template>
	<div class="news_banner-1">
		<div class="news_banner">
			<img :src="banner[0].bannerImg" alt="" width="100%" />
		</div>
		<el-row :gutter="20" type="flex" class="tabs">
			<el-col :span="4" :offset="0" class="tabsx">
				<div class="title" :class="{ active: showNewor == '5' }" @click="showNeworsA('5')">
					<!-- 公司新闻 -->
					{{$t('newList.Company News')}}
				</div>
			</el-col>
			<el-col :span="4" :offset="0" class="tabsx">
				<div class="title" :class="{ active: showNewor == '6' }" @click="showNeworsA('6')">
					<!-- 技术进展 -->
					{{$t('newList.Technology Progress')}}
				</div>
			</el-col>
			<el-col :span="4" :offset="0" class="tabsx">
				<div class="title" :class="{ active: showNewor == '8' }" @click="showNeworsA('8')">
					<!-- 产品动态 -->
					{{$t('newList.Product News')}}
				</div>
			</el-col>
			<el-col :span="4" :offset="0" class="tabsx">
				<div class="title" :class="{ active: showNewor == '9' }" @click="showNeworsA('9')">
					<!-- 活动信息 -->
					{{$t('newList.Event News')}}
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="news">
			<el-col :span="16" :offset="0">
				<div class="hebing">
					<div class="aa1-3">
						<!-- {{ tit }} -->
						<span class="bbstyle" v-if="showNewor === '5'">{{$t('newList2.News list')}}</span>
						<span class="bbstyle" v-else-if="showNewor === '6'">{{$t('newList2.Technology Progress')}}</span>
						<span class="bbstyle" v-else-if="showNewor === '8'">{{$t('newList2.Product News')}}</span>
						<span class="bbstyle" v-else-if="showNewor === '9'">{{$t('newList2.Event News')}}</span>
						</div>
					<el-row>
						<el-col :span="8" :offset="0" v-for="item in newList" :key="item.id" class="bsoo">
							<div class="chufa">
								<div class="bs0">
									<!-- <img :src="item.articleCovers" alt="" width="100%" height="104px"/> -->
									<div class="articleCoversImg-item">
										<img :src="item.articleCovers" alt="" />
									</div>

									<div class="zzc0-0">
										<!-- <div class="zzc0-0-1">20</div>
										<div class="zzc0-0-2">Aug</div> -->
									</div>
									<div class="zzc0">
										<div class="zzc0-1">{{ item.articleTitle }}</div>
										<div class="zzc0-2" v-html="item.articleDigest"></div>
										<div class="zzc0-3" @click="toDetails(item.code)">
											<!-- 新闻详情 -->{{$t('newList.News Details')}}
										</div>
										<div class="zzc0-4"><i class="el-icon-date"></i>{{ item.createTime.substring(0,10) }}</div>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-col>
		</el-row>
		<!-- <div class="more-star moreStarAct" @click="getMore" >
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div> -->
		<div style="width: 100%; text-align: center" v-if="loading">
			<div class="aa1-2" @click="getMore()">
				<!-- 加载更多 -->{{$t('newList.Read More')}}
			</div>
		</div>

		<div style="height: 125px"></div>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			// title: "新闻", // set a title
			meta: [{
					// set meta
					name: "keyWords",
					content: "锐思",
				},
				{
					name: "description",
					content: "我是description描述",
				},
			],
			link: [{
				// set link
				rel: "asstes",
				href: "www.baidu.com",
			}, ],
		},
		name: "newList",
		data() {
			return {
				tit: "新闻列表",
				showNewor: "5", //默认请求新闻列表
				newList: [],
				loading: true,
				pageNum: 1,
				pageSize: 6,
				zys: "",
				banner: [{
					bannerImg: "",
				}, ],
			};
		},
		computed: {
			language() {
				return this.$store.state.Language
			}
		},
		watch: {
			language(newVal, oldVal) {
				window.document.title = this.$t('newList.title')
				this.getBanner()
				let size =  this.newList.length
				this.newList = []  //语言发生变化 清空数组
				if(size <= 6){
					this.getNewlist(this.showNewor);
				}else{
					this.getNewlist(this.showNewor,1,size);
				}
				console.log(size)
				
				
				// this.getBanner();
			}

		},
		created() {
			window.document.title = this.$t('newList.title')
			this.getNewlist("5"); //公司新闻
			this.getBanner();
		},
		methods: {
			toDetails(id) {
				this.$router.push({
					path: '/newsDetails?id=' + id
				});
			},
			getBanner() {
				let language = this.$store.state.Language
				this.$http.get("/appBanner/getBannerList?spaceId=12&language="+language).then((res) => {
					this.banner = res.data;
					console.log(this.banner);
				});
			},

			showNewors(index, ti) {
				this.loading = true;
				this.tit = ti;
				this.showNewor = index;
				this.pageNum = 1;
				this.pageSize = 6;
				this.zys = "1";
				this.getNewlist(index);
			},

			newsDetails(id) {
				// console.log(id);
				this.$router.push({
					path: "/news",
					query: {
						id
					}
				});
			},
			showNeworsA(index){  //改变分类
				if(this.showNewor === index) return //如果请求类型重复点击，则不操作
				
					this.pageNum = 1 //从第一页刚开始
					this.newList = [] //清空其他分类数据
					this.showNewor = index
					this.getNewlist(this.showNewor)
				
			},
			async getNewlist(index,pageNum,pageSize) { //请求新闻列表
				let language = this.$store.state.Language
				var res;
				if(pageNum == undefined && pageSize == undefined){
					res = await this.$http.get(
						`/newList/list?parentId=${index}&pageNum=${this.pageNum}&pageSize=${this.pageSize}&language=${language}&orderByColumn=create_time&isAsc=desc`
						)
				}else{
					res = await this.$http.get(
						`/newList/list?parentId=${index}&pageNum=${pageNum}&pageSize=${pageSize}&language=${language}&orderByColumn=create_time&isAsc=desc`
						)
				}
				if (res.code === 200) { //响应成功
					res.rows.forEach(item=>{
						this.newList.push(item) //追加数据
					})
					this.newList.length >= res.total?this.loading = false:this.loading = true //判断数据如果全部请求完，则让加载更多按钮隐藏
				} else {
					console.log('数据请求失败!')
				}
				console.log(this.newList)
			},
			getMore(){  //加载更多
				this.pageNum++; 
				this.getNewlist(this.showNewor)
			}
		},
	};
</script>

<style scoped lang="scss">
	body {
		background-color: rgb(35, 80, 199) !important;
	}

	.aa1-2 {
		background-color: rgb(35, 80, 199);
		height: 40px;
		width: 155px;
		margin: auto;
		border-radius: 5px;
		margin-top: 50px;
		// margin-bottom: 125px;
		line-height: 36px;
		color: rgb(255, 255, 255);
		font-size: 18px;
		text-align: center;
		box-sizing: border-box;
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
	}

	.aa1-2:hover {
		background-color: rgb(255, 255, 255);
		height: 40px;
		width: 155px;
		margin: auto;
		border-radius: 5px;
		margin-top: 50px;
		// margin-bottom: 125px;
		line-height: 40px;
		color: rgb(35, 80, 199);
		font-size: 18px;
		text-align: center;
		box-sizing: border-box;
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
		cursor: pointer;
	}

	.rs0 {
		height: 300px;
	}

	.rs1 {
		width: 100%;
		text-align: center;
		font-size: 65px;
		// margin-top: 310px;
		font-weight: 500;
		color: #fff;
	}

	.rs2 {
		width: 100%;
		text-align: center;
		font-size: 45px;
		margin-top: 0px;
		font-weight: 500;
		color: #fff;
		position: relative;
		top: -5px;
	}

	.hebing {
		text-align: center;
	}

	.aa1-3 {
		font-size: 30px;
		color: rgb(0, 0, 0);
		margin-top: 40px;
		margin-bottom: 45px;
	}

	.aa1-1-3-4 {
		color: rgb(147, 149, 148);
		margin-bottom: 50px;
		margin-top: 8px;
	}

	.chufa {
		// background-color: aqua;
		margin: 3.5%;
		margin-bottom: 28px;
	}

	.bs0 {
		position: relative;
		// height: 300px;
		background-color: #fff;

		.articleCoversImg-item {
			width: 100%;
			height: 204px;

			img {
				width: 100%;
				object-fit: cover;
			}

			// height: expression(document.getElementsByClassName("bs0")[0].width + "px");
			// $w : expression(document.getElementsByClassName("articleCoversImg-item")[0].width + "px");//获取图片宽度
		}
	}

	// .zzc {
	//   background-color: rgba(255, 255, 255, 0);
	//   position: absolute;
	//   opacity: 0;
	//   top: 0;
	//   width: 100%;
	//   height: 100%;
	//   transition: 0.5s;
	//   padding: 5%;
	//   color: rgb(212, 212, 212);
	//   box-sizing: border-box;
	//   display: -webkit-box; //对象作为弹性伸缩盒子模型显示
	//   overflow: hidden; //溢出隐藏
	//   -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
	//   -webkit-line-clamp: 10; //设置 块元素包含的文本行数
	// }
	.zzc0-0 {
		width: 60px;
		height: 60px;
		// background-color: rgb(35, 80, 199);
		position: relative;
		top: -60px;
		// border-top-right-radius: 10px;
		color: rgb(255, 255, 255);
		// line-height: 10px;
		font-weight: 700;

		.zzc0-0-1 {
			font-size: 12px;
			padding-top: 15px;
		}

		.zzc0-0-2 {
			font-size: 12px;
		}
	}

	.zzc0 {
		color: rgb(0, 0, 0);
		font-weight: 600;
		transition: 0.5s;
		background-color: #fff;
		padding: 11%;
		padding-top: 0%;
		box-sizing: border-box;
		text-align: left;

		.zzc0-1 {
			height: 75px;
			font-size: 18px;
			line-height: 25px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}

		.zzc0-2 {
			font-size: 14px;
			font-weight: 400;
			color: rgb(100, 99, 105);
			line-height: 30px;
			margin-top: 10px;
			margin-bottom: 10px;

		}

		.zzc0-3 {
			font-size: 14px;
			transition: 0.3s;
		}

		.zzc0-3:hover {
			color: rgb(33, 78, 190);
			transition: 0.3s;
			cursor: pointer;
		}
		.zzc0-4{
			margin-top: 8px;
			font-size: 12px;
		}
	}

	.bs1 {
		position: relative;
		top: -4px;
		line-height: 50px;
		height: 50px;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
		transition: 0.5s;
	}

	// .chufa:hover .zzc {
	//   background-color: rgba(0, 0, 0, 0.7);
	//   transition: 0.5s;
	//   opacity: 1;
	// }

	// .chufa:hover .zzc0 {
	//   color: rgb(196, 196, 196);
	//   transition: 0.5s;
	// }

	.chufa:hover .bs1 {
		color: rgb(255, 255, 255);
		background-color: rgb(35, 80, 199);
		transition: 0.5s;
	}

	::v-deep.el-pagination.is-background .el-pager li {
		background-color: rgb(255, 255, 255) !important;
	}

	::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: rgb(245, 245, 247) !important;
		color: #606266;
	}

	.news_banner {
		// background: url(../assets/images/cptou.jpg) no-repeat;
		// background-size: 100% 100%;
		// height: 100%;
		// display: flex;
		// justify-content: flex-start;
		align-items: center;
		text-align: center;
		width: 100%;

		p {
			padding-left: 100px;
		}
	}

	.news_banner-1 {
		background-color: rgb(245, 245, 247);
	}

	.active {
		color: rgb(255, 255, 255) !important;
		// box-shadow: 0 0 10px rgb(211, 211, 211) inset;
		background-color: rgb(34, 81, 197) !important;
		transition: 0.5s;
	}

	.tabs {
		display: flex;
		justify-content: center;
		// padding: 0px 15%;
		// justify-content: space-around;
		margin-top: 40px;
		// margin: 1.5%;
	}

	.title {
		// width: 200px;
		text-align: center;
		// padding:45px 0px 0px 0px ;
		cursor: pointer;
		font-size: 22px;
		// font-weight: 700;
		color: rgb(0, 0, 0);
		padding: 8px;
		border-radius: 8px;
		background-color: rgb(255, 255, 255);
		transition: 0.5s;

		i {
			margin-right: 10px;
		}

		li {
			width: 120px;
			margin: auto;
			font-size: 16px;
			list-style: none;
			// border-bottom: 4px solid rgb(223,1,16);
			position: relative;
		}
	}

	.title:hover {
		// width: 200px;
		text-align: center;
		// padding:45px 0px 0px 0px ;
		cursor: pointer;
		font-size: 22px;
		// font-weight: 700;
		color: rgb(255, 255, 255);
		padding: 8px;
		border-radius: 8px;
		background-color: rgb(34, 81, 197);
		transition: 0.5s;

		i {
			margin-right: 10px;
		}

		li {
			width: 120px;
			margin: auto;
			font-size: 16px;
			list-style: none;
			// border-bottom: 4px solid rgb(223,1,16);
			position: relative;
		}
	}

	.eng-name {
		text-align: center;
		color: rgb(140, 140, 140);
		font-size: 18px;
		font-weight: 200;
		padding-bottom: 14px;
	}

	.lineLi::after {
		content: "";
		position: absolute;
		top: 48%;
		left: 60%;
		bottom: 48%;
		right: 0;
		background: rgb(196, 0, 10);
	}

	.lineLi::before {
		content: "";
		position: absolute;
		top: 48%;
		left: 0;
		bottom: 48%;
		right: 60%;
		background: rgb(196, 0, 10);
	}

	.news {
		display: flex;
		justify-content: center;
	}

	.news-col {
		display: flex;
		flex-wrap: wrap;
	}
	.bbstyle{
		font-family: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
	}
	.news_list {
		height: 550px;
		margin-top: 90px;
		width: 100%;
		// margin-left: 4%;
		border-radius: 5px;
		// box-shadow: 0px 0px 3px #ccc;
		background-color: white;
		overflow: hidden;
		box-shadow: 2px 2px 5px rgb(128, 128, 128);
		margin-bottom: 70px;

		.news-image {
			width: 50%;
			height: 100%;
			display: inline-block;

			img {
				height: 100%;
				width: 100%;
			}
		}

		.news_list-1 {
			width: 50%;
			display: inline-block;

			// height: 300px;
			h3 {
				// height: 55px;
				padding-bottom: 18px;
				position: relative;
				top: -125px;
				font-size: 30px;
				left: 10px;
				margin: 40px;
				border-bottom: 2px solid #aaa;
			}

			.news-time {
				display: inline-block;
				padding-left: 40px;
				color: #aaa;
				font-size: 18px;
				font-weight: 700;
				height: 30px;
				padding-bottom: 20px;
				position: relative;
				top: -50px;
				left: 10px;
			}

			.news-time1 {
				float: right;
				display: inline;
				padding-left: 20px;
				color: #aaa;
				font-size: 18px;
				// font-weight: 700;
				height: 30px;
				padding-bottom: 20px;
				position: relative;
				top: -55px;
				left: -50px;
			}

			.news-time1:hover {
				color: rgb(255, 0, 0);
			}

			.news-jianjie {
				color: #aaa;
				padding: 40px;
				padding-bottom: 0;
				// height: 100px;
				display: -webkit-box;
				font-weight: 500;
				overflow: hidden;
				white-space: normal !important;
				text-overflow: ellipsis;
				word-wrap: break-word;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				position: relative;
				line-height: 26px;
				top: -185px;
				left: 10px;
				font-size: 16px;
			}
		}
	}

	.news_list:not(:nth-child(1), :nth-child(1)) {
		width: 100%;
		height: 197px;
		background-color: white;
		box-shadow: 0px 0px 0px rgb(128, 128, 128);
		margin-bottom: 0px;
		margin-top: 40px;

		.news_list-1 {
			width: 75%;
		}

		h3 {
			position: relative;
			top: 5px;
			left: 5px;
			font-size: 22px;
			margin: 20px;
			border-bottom: 0px solid #aaa;
		}

		.news-image {
			width: 25%;
			height: 100%;
			float: right;
			padding: 20px;
			box-sizing: border-box;

			img {
				border-radius: 5px;
				height: 100%;
				width: 100%;
			}
		}

		.news-jianjie {
			display: block;
			font-size: 16px;
			margin-left: 16px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			position: relative;
			padding-right: 0;
			line-height: 26px;
			top: -65px;
			left: -32px;
		}

		.news-time {
			position: relative;
			top: -53px;
			left: -15px;
		}

		.news-time1 {
			position: relative;
			top: -600px;
			left: -7px;
		}
	}

	.more-star {
		// width: 90px;
		border-radius: 10px;
		padding: 5px 3px;
		margin: 52px auto 30px;
		cursor: default;
		font-size: 12px;
		// background: #eee;
		text-align: center;
		color: #aaa;
	}

	.moreStarAct {
		// background: rgb(196, 0, 10);
		color: #fff;
		cursor: pointer;
	}
</style>

//
<style scoped lang="scss">
	@media screen and (max-width: 1024px) {

		a,
		span,
		li,
		b,
		i,
		label,
		p,
		strong,
		div,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		small,
		em,
		li,
		pre,
		form,
		fieldset,
		legend,
		button,
		input,
		textarea,
		th,
		td {
			font-size: 14px;
			font-family: word;
		}
	}

	@media screen and (max-width: 1280px) {
		.f-20 {
			font-size: 14px;
		}
	}

	@media screen and (max-width: 800px) {
		.wrapper {
			overflow: visible;
		}
	}

	@media screen and (max-width: 1366px) {
		.f-20 {
			font-size: 18px;
		}
	}

	@media screen and (max-width: 1500px) {
		.f-20 {
			font-size: 18px;
		}
	}

	body,
	html {
		height: 100%;
	}

	#new {
		background-color: #f5f5f6;
	}

	.after-inner .wrapper {
		padding-top: 0.9rem;
	}

	.moreStarAct {
		background: rgb(196, 0, 10);
		color: #fff;
		cursor: pointer;
	}

	#bloc {
		min-height: 100vh;
	}

	.wrapper {
		overflow: hidden;
		position: relative;
		z-index: 2;
	}

	#new #main {
		padding: 5vw 0 1vw 0;
	}

	.max-wid {
		width: 66%;
		max-width: 1400px;
		margin: auto;
	}

	#new #main .rowMain .wrap {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-left: -0.6rem;
		flex-wrap: wrap;
	}

	#new #main .rowMain .wrap .box {
		width: calc(100% / 3 - 0.6rem - 1px);
		margin-left: 0.6rem;
		margin-bottom: 0.6rem;
		transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
	}

	.pic {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;
		position: relative;
		width: 100%;
		max-width: 100%;
		min-height: 200px;
	}

	.pic img.w-h {
		width: 100%;
		max-width: 100%;
		opacity: 1;
		min-height: 200px;
	}

	img {
		vertical-align: top;
		border: 0;
	}

	#new #main .rowMain .wrap .box .msg {
		padding: 5% 6% 12% 6%;
		background-color: #fff;
	}

	#new #main .rowMain .wrap .box .msg small {
		height: 0.46rem;
		line-height: 0.46rem;
		position: relative;
	}

	.mb-16 {
		margin-bottom: 16px;
	}

	.en-m {
		font-family: en-m;
	}

	#new #main .rowMain .wrap .box .msg small:after {
		content: none;
		position: absolute;
		width: 28px;
		height: 0.04rem;
		background-color: #000;
		bottom: 0;
		left: 0;
	}

	.mb-20 {
		margin-bottom: 20px;
	}

	.f-20 {
		font-size: 20px;
	}

	.over-2 {
		line-height: 24px;
		height: 48px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.f-center {
		text-align: center;
	}

	.layer-page {
		border: 1px solid #f3f3f3;
		overflow: hidden;
		display: inline-block;
		vertical-align: bottom;
	}

	.layer-page>a {
		float: left;
		width: 0.6rem;
		height: 0.6rem;
		line-height: 0.6rem;
		text-align: center;
		transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		font-weight: bolder;
	}

	a {
		cursor: pointer;
	}

	#new #main .rowMain .wrap .box .msg {
		padding: 5% 6% 12% 6%;
		background-color: #fff;
	}

	@media screen and(max-width: 700px) {
		.msg {
			height: 70px;
		}

		.bsoo {
			width: 100%;
		}

		.zzc0-0[data-v-74996398] {
			top: -60px;
		}

		.tabs {
			display: flex;
			justify-content: center;
			// padding: 0px 15%;
			// justify-content: space-around;
			margin-top: 0px;
			padding: 10%;
			flex-wrap: wrap;
		}

		.tabsx {
			width: 50%;
			margin-bottom: 20px;
		}

		.box {
			width: 100% !important;
		}
	}

	.box {
		max-height: 550px;
		cursor: pointer;
	}

	.pic {
		max-height: 200px !important;
	}

	.msg1 {
		display: flex;
		justify-content: space-between; //子元素水平左右两端对齐
	}

	.mb-c {
		font-size: 13px;
	}
</style>
